
import { defineComponent, reactive, ref } from 'vue'
import { fetchFeaturedItems } from '@/services/API'
import { FeedState } from '@/dto/FeedState'
import FeedComponent from '@/views/components/feed/FeedComponent.vue'
import HFeedComponent from '@/views/components/feed/HFeedComponent.vue'
import { LoadingState } from '@/dto/enums/LoadingState'
import { logDownloadBadgeClick } from '@/services/EventLogger'
import { useI18n } from 'vue-i18n'
import { createAppstoreLink, createGoogleStoreLink } from '@/services/DynamicLink'

export default defineComponent({
  components: {
    FeedComponent
  },

  setup() {
    const childComponentRef = ref<InstanceType<typeof HFeedComponent>>()
    const newFeed = reactive(new FeedState())

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { newFeed, LoadingState, t, childComponentRef }
  },

  methods: {
    downloadBadgeClick() {
      logDownloadBadgeClick()
    },

    appstoreLink(): string {
      return createAppstoreLink()
    },

    googlePlayLink(): string {
      return createGoogleStoreLink()
    }
  },

  mounted() {
    // fetchTopItems().then(response => {
    //   this.topFeed.items = response.items
    //   this.topFeed.loadingState = LoadingState.Loaded
    //   console.log(this.childComponentRef)
    //   if (this.childComponentRef) {
    //     this.childComponentRef.changeCellWidth()
    //   }
    //   // console.log(topFeed)
    // })

    fetchFeaturedItems().then(response => {
      this.newFeed.items = response.items
      this.newFeed.loadingState = LoadingState.Loaded
      // console.log(newFeed)
    })
  }
})
